<template>
  <div class="pa-6">
    <v-card elevation="2">
      
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="ml-2 my-2" depressed color="primary" :loading="categoriesState.isLoading">
            <span>Создать</span>
            <v-icon class="ml-2" small>mdi-plus-circle-outline</v-icon>
          </v-btn>

          <v-btn class="ml-4 my-2 mr-2" depressed color="primary" @click="isUploadOpen = true">
            <span>Загрузить</span>
            <v-icon class="ml-2" small>mdi-cloud-upload-outline</v-icon>
          </v-btn>
        </template>
        <v-sheet max-width="750">
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="category in medRecordTemplateCategories" :key="'categories_'+category.id">
              <v-expansion-panel-header class="font-weight-medium">{{ category.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="category.medRecordTemplatecategories.length===0" class="text-center caption pa-1">Не найдено</div>
                <v-expansion-panels v-if="category.medRecordTemplatecategories.length>1" accordion class="expansionPanelContentExpanded">
                  <v-expansion-panel v-for="medRecordTemplateCategory in category.medRecordTemplatecategories" :key="'categories1_'+medRecordTemplateCategory.id">
                    <v-expansion-panel-header class="font-weight-medium">{{ medRecordTemplateCategory.name }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list>
                        <v-list-item v-for="medRecordTemplate in medRecordTemplateCategory.medRecordTemplates" :key="'medRecordTemplate_'+medRecordTemplate.id" @click="medRecordCreate(medRecordTemplate.id)">
                          <v-list-item-title class="body-2 pl-2">{{ medRecordTemplate.name }}</v-list-item-title>
                        </v-list-item>
                        <div v-if="medRecordTemplateCategory.medRecordTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <template v-else>
                  <template v-for="medRecordTemplateCategory in category.medRecordTemplatecategories" >
                      <v-list v-if="medRecordTemplateCategory.medRecordTemplates" :key="'categories1_'+medRecordTemplateCategory.id">
                        <v-list-item v-for="medRecordTemplate in medRecordTemplateCategory.medRecordTemplates" :key="'medRecordTemplate_'+medRecordTemplate.id" @click="medRecordCreate(medRecordTemplate.id)">
                          <v-list-item-title class="body-2 pl-2">{{ medRecordTemplate.name }}</v-list-item-title>
                        </v-list-item>
                        <div v-if="medRecordTemplateCategory.medRecordTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                      </v-list>               
                  </template>
                </template>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-menu>
     
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="ml-2 my-2" depressed color="primary" :loading="categoriesState.isLoading || mediaCreateState.isLoading">
            <span>Создать из документа</span>
            <v-icon class="ml-2" small>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-sheet max-width="750">
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="category in mediaTemplateCategories" :key="'categories_'+category.id">
              <v-expansion-panel-header class="font-weight-medium">{{ category.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="category.subcategories.length===0&&category.mediaTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                <v-expansion-panels accordion class="expansionPanelContentExpanded">
                  <v-expansion-panel v-for="subcategory in category.subcategories" :key="'categories1_'+subcategory.id">
                    <v-expansion-panel-header class="font-weight-medium">{{ subcategory.name }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list>
                        <v-list-item v-for="mediaTemplate in subcategory.mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                          <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                        </v-list-item>
                        <div v-if="typeof subcategory.mediaTemplates !=='undefined'&&subcategory.mediaTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                      </v-list>
                    </v-expansion-panel-content>

                  </v-expansion-panel>
                </v-expansion-panels>
                   <v-list v-if="category.mediaTemplates.length!==0">
                      <v-list-item v-for="mediaTemplate in category.mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                        <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
              </v-expansion-panel-content>           
            </v-expansion-panel>
          </v-expansion-panels>
          <v-list v-if="mediaTemplates.length>0">
            <v-divider/>
            <v-list-item v-for="mediaTemplate in mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
              <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-menu>

      <v-data-table
          :headers="headers"
          :items="medRecords"         
          :loading="mediaRecordState.isLoading"
          dense
          :height="height"
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item="{ item }">
          
          <tr @click="typeof item.medRecordTemplate !== 'undefined' ? medRecordId=item.id : mediaId=item.id">
            <td class="text-no-wrap">{{item.id}}</td>
            <td>{{item.name!=null&&item.name!='' ? item.name : typeof item.medRecordTemplate !== 'undefined' ? item.medRecordTemplate.name : '-'}} <v-icon v-if="(typeof item.medRecordTemplate === 'undefined')" small class="ml-1" title="Документ">mdi-file</v-icon> <v-icon v-if="!item.isShowInService" class="ml-2" size="14" color="primary" title="Скрыто в личном кабинете">mdi-eye-off-outline</v-icon></td>
            <td>{{item.comment}}</td>
            <td class="text-no-wrap">
              <div v-if="misDesktopOnline&&typeof item.medRecordTemplate === 'undefined'">
                <v-btn small depressed @click.stop="misDesktopOpenFile(item)"><v-icon class="mr-2" small>mdi mdi-open-in-new</v-icon>Открыть локально</v-btn>
              </div>
            </td>
            <td>
              <v-tooltip v-if="item.status==='unactive'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="mr-2">mdi-file-alert</v-icon>
                </template>
                <span>Черновик</span>
              </v-tooltip>
            </td>
            <td class="text-no-wrap">
              <v-avatar size="32" class="mr-2" v-if="typeof item.userAuthor.imageUrl !== 'undefined'&&item.userAuthor.imageUrl!==null">
                <img :src="item.userAuthor.imageUrl"/>
              </v-avatar>
              {{$tools.userFullName(item.userAuthor)}}
              
            </td>
            <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
            <td class="text-no-wrap">
              <v-chip
                v-if="item.isSent"
                class="font-weight-medium"
                color="green"
                text-color="white"
                small
              >
                Отправлено
              </v-chip>
            </td>
            <td>
              <div class="action-btns">
                <v-btn icon x-small @click.stop="downloadAction(item)" :loading="mediaRecordState.isLoading">
                  <v-icon small title="Скачать">mdi-download</v-icon>
                </v-btn>
                <v-btn icon x-small @click.stop="typeof item.medRecordTemplate !== 'undefined' ? preview(item) : mediaId=item.id" :loading="previewState.isLoading">
                  <v-icon small title="Предпросмотр">mdi-eye</v-icon>
                </v-btn>
                <v-btn :class="[item.isPrinted ? 'btnSuccessIco btnSuccessIco_dark btnSuccessIco_small' : '']" icon x-small @click.stop="print(item)" :loading="printState.isLoading">
                  <v-icon small title="Печать">mdi-printer</v-icon>
                </v-btn>
                <btn-ico-accept :btnClass="[item.isSent ? 'btnSuccessIco btnSuccessIco_dark btnSuccessIco_small' : '']" text="Отправить на E-mail?" icon="mdi-email-arrow-right" btnIcon xSmall @accept="sendEmail(item)" :loading="emailState.isLoading"/>
                <btn-ico-accept text="Удалить?" icon="mdi-delete" btnIcon xSmall @accept="deleteAction(item)" :loading="mediaRecordState.isLoading"/>
                <btn-ico-accept v-if="typeof item.medRecordTemplate !== 'undefined'" text="Создать копию?" icon="mdi-content-copy" btnIcon xSmall @accept="copyAction(item)" :loading="mediaRecordState.isLoading"/>   
                
                <v-btn v-if="misDesktopOnline&&typeof item.medRecordTemplate === 'undefined'" icon x-small @click.stop="misDesktopOpenFile(item)" :loading="printState.isLoading">
                  <v-icon small title="Открыть локально">mdi-open-in-new</v-icon>
                </v-btn>

              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <med-record-update :med-record-id.sync="medRecordId" @medRecordCopied="medRecordCopied" :userId="$router.currentRoute.params.id"/>

    <media-view :media-id.sync="mediaId" :user-id="$router.currentRoute.params.id" :userName="userName" @updateList="mediaRecordLoad()"/>

    <uploader
        :dialog.sync="isUploadOpen"
        :multiple="true"
        :url="'/api/media/media/upload/?mediaDirId=2&userId='+$router.currentRoute.params.id"
        @uploadComplete="uploadComplete"
    />

    <v-dialog
        v-model="previewState.isModalOpen"
        fullscreen
        hide-overlay
        scrollable
        transition="dialog-bottom-transition"
    >
      <v-card class="grey lighten-4">
        <v-toolbar dark :color="!$vuetify.theme.dark ? 'rgb(2 146 77)' : 'rgb(40 40 40)'" max-height="64px">
          <v-btn icon dark @click="previewState.modalClose()">
            <v-progress-circular v-if="previewState.isLoading" indeterminate size="20" width="3"/>
            <v-icon v-else>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <div class="caption">Предосмотр Мед.Запись</div>
            <div class="body-1">{{ previewState.modalData!=null && previewState.modalData.name!='' ? previewState.modalData.name : previewState.modalData?.medRecordTemplate.name }}</div>
          </v-toolbar-title>
        </v-toolbar>
        <div>
          <iframe
              :src="previewState.modalData?.previewUrl"
              frameBorder="0"
              :style="'width:'+previewWidth+'px;height:'+previewHeight+'px'"
          ></iframe>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import MedRecordUpdate from "@/views/MedRecord/MedRecordUpdate";
import MediaView from "@/views/Media/MediaView";
import Uploader from "@/components/Uploader";
import axios from "axios";
import printJS from "print-js";
import BtnIcoAccept from "@/componentsV2/base/BtnIcoAccept.vue";

import {
  Entity_MedRecord,
  Entity_MedRecordTemplateCategory,
  Entity_Spec,
  Entity_MedRecordTemplate,
  Entity_MediaTemplateCategory,
  Entity_MediaTemplate,
  Entity_Media,
  Entity_User,
  Entity_MediaDir
} from "../../../../EntityStoreCacheService";

export default {
  components: {MedRecordUpdate, MediaView, Uploader, BtnIcoAccept},
  props:['userName'],
  data: () => ({
    categoriesState: new State(),
    medRecordTemplateCategories: [],
    headers: [
      { text: 'ID', value: 'id', sortable: true, class:'text-no-wrap', width:'auto'},
      { text: 'Название', value: 'medRecordTemplate.name', sortable: true, class:'text-no-wrap'},
      { text: 'Комментарий', sortable: false},
      { text: '', sortable: false},
      { text: '', sortable: false},
      { text: 'Автор', value: 'userAuthor', sortable: false, class:'text-no-wrap'},
      { text: 'Дата создания', value: 'created', sortable: true, class:'text-no-wrap'},
      { text: 'Отправка на Email', sortable: false},
      { text: '', sortable: false},
    ],
    mediaRecordState: new State(),
    mediaRecordCreateState: new State(),
    medRecords:[],
    medRecordId:null,
    medias: [],
    mediaId: null,
    isUploadOpen: false,
    height:null,

    mediaTemplateCategories: [],
    mediaCreateState: new State(),
    mediaCreateErrorState: new State(),

    mediaTemplates: [],
    misDesktopOnline:false,

    previewState: new State(),
    previewWidth:0,
    previewHeight:0,

    printState: new State(),
    emailState: new State(),
  }),
  mounted() {
    this.height = window.innerHeight - 200-12;
    this.loadTemplatesCategories();
    this.$tools.misDesktopStatus((v)=>{
      this.misDesktopOnline = v;
    });
  },
  watch: {
    medRecordId(){
      if (this.medRecordId===null)
      this.mediaRecordLoad();
    }
  },
  methods:{
    loadTemplatesCategories(){
      this.categoriesState.stateLoading();
      let medRecordTemplateCategory = new Entity_MedRecordTemplateCategory()
      .selects(Entity_MedRecordTemplateCategory.id)
      .selects(Entity_MedRecordTemplateCategory.name)
      .selects(Entity_MedRecordTemplateCategory.status)

      .selects([Entity_MedRecordTemplateCategory.spec, Entity_Spec.id].join('.'))
      .selects([Entity_MedRecordTemplateCategory.spec, Entity_Spec.name].join('.'))

      .selects([Entity_MedRecordTemplateCategory.medRecordTemplates, Entity_MedRecordTemplate.id].join('.'))
      .selects([Entity_MedRecordTemplateCategory.medRecordTemplates, Entity_MedRecordTemplate.name].join('.'))
      .selects([Entity_MedRecordTemplateCategory.medRecordTemplates, Entity_MedRecordTemplate.status].join('.'))
      .selects([Entity_MedRecordTemplateCategory.medRecordTemplates, Entity_MedRecordTemplate.sort].join('.'))

      //filter by status
      .filterAnd([Entity_MedRecordTemplateCategory.entity, Entity_MedRecordTemplateCategory.status].join('.')+'=?','active')

      .page(1).onPage(3000)

      medRecordTemplateCategory.request((data)=>{      

        let mainCategories = [...new Map(data.items.map(i => i.spec).map(item => [item.id, item])).values()];

        mainCategories.sort((a, b) => a.name.localeCompare(b.name));

        mainCategories.forEach(cat=>{
          data.items.forEach(i=>{
            if(i.spec.id===cat.id){
              if (!('medRecordTemplatecategories' in cat)) {
                cat.medRecordTemplatecategories = [];
              }
              cat.medRecordTemplatecategories.push({id:i.id,name:i.name,status:i.status,medRecordTemplates:i.medRecordTemplates.filter(i=>i.status==='active').sort((a, b) => a.sort - b.sort)})
              cat.medRecordTemplatecategories.sort((a, b) => a.name.localeCompare(b.name));
            }
          });
        });

        console.log(mainCategories);
        this.medRecordTemplateCategories = mainCategories;
        this.mediaRecordLoad();
      }, (e)=>{
        console.error(e.response.data.message);
        this.categoriesState.stateError();
      })

      //mediaTemplateCategory
      let mediaTemplateCategory =  new Entity_MediaTemplateCategory()
      .selects(Entity_MediaTemplateCategory.id)
      .selects(Entity_MediaTemplateCategory.name)
      .selects(Entity_MediaTemplateCategory.status)
      .selects(Entity_MediaTemplateCategory.subcategories)


      //mediaTemplate
      .selects([Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.id].join('.'))
      .selects([Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.name].join('.'))
      .selects([Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.sort].join('.'))
      //.selects([Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.comBranch, Entity_ComBranch.id].join('.'))
      

      //subcategories
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.id].join('.'))
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.name].join('.'))
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.status].join('.'))
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.sort].join('.'))
     
      //subcategory mediaTemplates
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.id].join('.'))
      .selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.name].join('.'))
      //.selects([Entity_MediaTemplateCategory.subcategories, Entity_MediaTemplateCategory.mediaTemplates, Entity_MediaTemplate.comBranch, Entity_ComBranch.id].join('.'))

      .page(1).onPage(3000)

      .order(Entity_MediaTemplateCategory.name, true)
   
      //filter by status
      .filterAnd([Entity_MediaTemplateCategory.entity, Entity_MediaTemplateCategory.status].join('.')+'=?','active')

      //filter by parent
      .filterAnd([Entity_MediaTemplateCategory.entity, Entity_MediaTemplateCategory.parentCategory].join('.')+' is null')
  
      //filter by MediaDir
      .filterAnd([Entity_MediaTemplateCategory.entity, Entity_MediaTemplateCategory.mediaDir, Entity_MediaDir.id].join('.')+' NOT IN (?)',[1])

      mediaTemplateCategory.request((data)=>{
        let mediaCategories = data.items;

        //sort
        function sortMediaTemplates(category) {

          if (category.mediaTemplates) {
              category.mediaTemplates.sort((a, b) => a.sort - b.sort);
          }

          if (category.subcategories && category.subcategories.length > 0) {
              category.subcategories = category.subcategories.map(subcategory => sortMediaTemplates(subcategory));
          }

          return category;
        }

        const sortedData = mediaCategories.map(category => sortMediaTemplates(category));
        this.mediaTemplateCategories = sortedData;

        //mediaTemplates without cat
        let mediaTemplatesQuery = new Entity_MediaTemplate()
        .selects(Entity_MediaTemplate.id)
        .selects(Entity_MediaTemplate.name)
        .selects(Entity_MediaTemplate.sort)

        .filterAnd([Entity_MediaTemplate.entity, Entity_MediaTemplate.mediaDir, Entity_MediaDir.id].join('.')+' NOT IN (?)',[1])
        .filterAnd([Entity_MediaTemplate.entity, Entity_MediaTemplate.mediaTemplateCategory].join('.')+' is null')

          mediaTemplatesQuery.page(1).onPage(3000)
          mediaTemplatesQuery.order(Entity_MediaTemplate.sort, true)

    
          mediaTemplatesQuery.request((data)=>{
          this.mediaTemplates = data.items

          this.categoriesState.stateSuccess();
        }, (e)=>{
          console.error(e.response.data.message);
        })
      }, (e)=>{
        console.error(e.response.data.message);
        this.categoriesState.stateError();
      })

    },
    mediaRecordLoad(){
      this.mediaRecordState.stateLoading();

      const promises = [];
      const medRecords = new Promise((resolve) => {  
        let medRecordRequest = new Entity_MedRecord()
          .selects(Entity_MedRecord.id)
          .selects(Entity_MedRecord.created)
          .selects(Entity_MedRecord.isSent)
          .selects(Entity_MedRecord.isPrinted)
          .selects(Entity_MedRecord.isShowInService)
          .selects(Entity_MedRecord.name)
          .selects(Entity_MedRecord.status)
          .selects(Entity_MedRecord.comment)
       

          //user
          .selects([Entity_MedRecord.user,Entity_User.id].join('.'))
          .selects([Entity_MedRecord.user,Entity_User.firstName].join('.'))
          .selects([Entity_MedRecord.user,Entity_User.lastName].join('.'))
          .selects([Entity_MedRecord.user,Entity_User.secondName].join('.'))

          //user author
          .selects([Entity_MedRecord.userAuthor, Entity_User.id].join('.'))
          .selects([Entity_MedRecord.userAuthor, Entity_User.firstName].join('.'))
          .selects([Entity_MedRecord.userAuthor, Entity_User.lastName].join('.'))
          .selects([Entity_MedRecord.userAuthor, Entity_User.secondName].join('.'))
          .selects([Entity_MedRecord.userAuthor, Entity_User.imageUrl].join('.'))

          //medRecordTemplate
          .selects([Entity_MedRecord.medRecordTemplate, Entity_MedRecordTemplate.id].join('.'))
          .selects([Entity_MedRecord.medRecordTemplate, Entity_MedRecordTemplate.name].join('.'))

          .filterAnd([Entity_MedRecord.entity, Entity_MedRecord.user, Entity_User.id ].join('.')+' =?',this.$router.currentRoute.params.id)
          .filterAnd([Entity_MedRecord.entity, Entity_MedRecord.status].join('.')+'!=?','deleted')


          .page(1).onPage(10000)

          medRecordRequest.request((data)=>{
              resolve(data.items);
          }, (e)=>{
            console.error(e.response.data.message);
          })
      });

      promises.push(medRecords);

      const media = new Promise((resolve) => {
        let mediaRequest = new Entity_Media()
        .selects(Entity_Media.id)
        .selects(Entity_Media.created)
        .selects(Entity_Media.isSent)
        .selects(Entity_Media.isPrinted)
        .selects(Entity_Media.isShowInService)
        .selects(Entity_Media.name)
        .selects(Entity_Media.status)
        .selects(Entity_Media.fileName)

        //user
        .selects([Entity_Media.user,Entity_User.id].join('.'))
        .selects([Entity_Media.user,Entity_User.firstName].join('.'))
        .selects([Entity_Media.user,Entity_User.lastName].join('.'))
        .selects([Entity_Media.user,Entity_User.secondName].join('.'))

        //user
        .selects([Entity_Media.userAuthor, Entity_User.id].join('.'))
        .selects([Entity_Media.userAuthor, Entity_User.firstName].join('.'))
        .selects([Entity_Media.userAuthor, Entity_User.lastName].join('.'))
        .selects([Entity_Media.userAuthor, Entity_User.secondName].join('.'))
        .selects([Entity_Media.userAuthor, Entity_User.imageUrl].join('.'))


        .filterAnd([Entity_Media.entity, Entity_Media.user, Entity_User.id ].join('.')+' =?',this.$router.currentRoute.params.id)
        .filterAnd([Entity_Media.entity, Entity_Media.mediaDir, Entity_MediaDir.id ].join('.')+' NOT IN (?)',[1])
        .filterAnd([Entity_Media.entity, Entity_Media.status].join('.')+'!=?','deleted')

        .page(1).onPage(10000)

        mediaRequest.request((data)=>{
            resolve(data.items);
        }, (e)=>{
          console.error(e.response.data.message);
        })
      });
      promises.push(media);


      Promise.all(promises).then((result) => {
        let medRecordsAndMedia = [];
        result.forEach(arr=>{
          medRecordsAndMedia = medRecordsAndMedia.concat(arr);
        })

        //sort by created
        let sortedArray = medRecordsAndMedia.slice().sort(function(a, b) {
            return new Date(b.created) - new Date(a.created);
        });


        this.medRecords = sortedArray;

        this.mediaRecordState.stateSuccess();
      }).catch((error) => {
        console.error('Error:', error);
        this.mediaRecordState.stateError(error)
      }); 
          
    },
    medRecordCreate(medRecordTemplateId){
      this.mediaRecordCreateState.stateLoading();
      Api.service.medRecord.medRecordCreate(medRecordTemplateId, this.$router.currentRoute.params.id, (data)=>{
        this.mediaRecordCreateState.stateSuccess();
        this.medRecordId = data.id;
        this.loadTemplatesCategories();
      }, ()=>{
        this.mediaRecordCreateState.stateError();
      });
    },
    medRecordCopied(id){
      this.loadTemplatesCategories();
      this.medRecordId = id;
    },
    uploadComplete(){
      this.loadTemplatesCategories();
    },
    mediaCreateAction(mediaTemplateId){
      this.mediaCreateState.stateLoading();
      axios.post(Api.host+'/media/mediaTemplate/'+mediaTemplateId+'/mediaCreate/', {
        userId:this.$router.currentRoute.params.id
      })
          .then(r=>{
            this.mediaCreateState.stateSuccess();
            this.loadTemplatesCategories();
            this.$tools.misDesktopStatus((v)=>{
              if(v){
                this.$tools.misDesktopOpenFile(
                    r.data.fileName,
                    r.data.name + r.data.fileName.substring(r.data.fileName.lastIndexOf("."), r.data.fileName.length),
                    "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
                    {id: r.data.id},
                    ()=>{
                      this.$store.commit('alertSuccess', 'Файл открывается локально');
                    }, ()=>{
                      this.$store.commit('alertError', 'Не удалось открыть файл локально');
                    }
                );
              } else {
                this.mediaId = r.data.id;
              }
            });
          })
          .catch((e)=>{
            console.log(e);
            this.mediaCreateState.stateError();
          });
    },
    misDesktopOpenFile(v){
      axios.get(Api.host + '/media/media/' + v.id + '/').then(r1 => {
        let mediaData = r1.data;
        this.$tools.misDesktopOpenFile(
            mediaData.filename,
            mediaData.name + mediaData.filename.substring(mediaData.filename.lastIndexOf("."), mediaData.length),
            "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
            {
              id: v.id,
            },
            ()=>{
              this.$store.commit('alertSuccess', 'Файл открывается локально');
            }, ()=>{
              this.$store.commit('alertError', 'Не удалось открыть файл локально');
            }
        );
      });
    },
    downloadAction(item){
      this.mediaRecordState.stateLoading();
      function download(href,name) {
        const a = document.createElement('a');
        a.href = href;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      if (typeof item.medRecordTemplate!=='undefined') {
        let name = item.name!==null ? item.name : item.medRecordTemplate.name;
        Api.service.medRecord.medRecordDownload(item.id, (data)=>{
          for(let index in data){
            download(data[index],name+' ('+this.$tools.userFullName(item.user)+')'+'.docx')
            this.mediaRecordState.stateSuccess();
          }
        });
      }  else {
        let fileExtension = item.fileName.substring(item.fileName.lastIndexOf('.'))
        download('/media/'+item.fileName,item.name+' ('+this.$tools.userFullName(item.user)+')'+fileExtension )
        this.mediaRecordState.stateSuccess();
      }
    },
    preview(item) {
      this.previewState.stateLoading();
      this.previewWidth = window.innerWidth;
      this.previewHeight = window.innerHeight-64;

      Api.service.medRecord.medRecordDownload(item.id, (data)=>{
        for(let index in data){
          let obj = item;
          obj.previewUrl = 'https://view.officeapps.live.com/op/embed.aspx?src='+data[index];
          console.log(obj)
          this.previewState.modalOpen(obj);
        
        }
        this.previewState.stateSuccess();
      });
    },
    print(item){
      this.printState.stateLoading();
      if (typeof item.medRecordTemplate!=='undefined') {
        axios.get(Api.host+`/medRecord/medRecord/${item.id}/preview/`)
        .then(r=>{
          let images = [];
          r.data.forEach(v=>{
            images.push(v);
          });
          printJS({ printable: images, type: 'image', header: ''});
          this.printState.stateSuccess();
          this.mediaRecordLoad();
        }).catch(e=>{
          console.log(e);
          this.printState.stateError(e.response.data.errors);
        });
      }else {
        Api.service.media.mediaPreview(item.id, (data)=>{
          let images = [];
          data.forEach((v)=>{
            images.push(v);
          });
          printJS({ printable: images, type: 'image', header: ''});

          this.printState.stateSuccess();
          this.mediaRecordLoad();
        });
      }  
    },
    sendEmail(item){
      this.emailState.stateLoading();
      if (typeof item.medRecordTemplate!=='undefined') {
        Api.service.medRecord.medRecordEmailSend(item.id, ()=>{
          this.emailState.stateSuccess();
          this.emailState.modalClose();
          this.$store.commit('alertSuccess', 'Успешно отправлено');
          this.mediaRecordLoad();
        });
      } else {
        Api.service.media.mediaEmailSend(item.id, ()=>{
          this.emailState.stateSuccess();
          this.emailState.modalClose();
          this.$store.commit('alertSuccess', 'Успешно отправлено');
          this.mediaRecordLoad();
        });
      }  
    },
    deleteAction(item){
      this.mediaRecordState.stateLoading();
      if (typeof item.medRecordTemplate!=='undefined') {
        Api.service.medRecord.medRecordDelete(item.id, ()=>{
          this.mediaRecordState.stateSuccess();
          this.mediaRecordLoad();
        });
      } else {
        Api.service.media.mediaDelete(item.id, ()=>{
          this.mediaRecordState.stateSuccess();
          this.mediaRecordLoad();
        });
      } 
    },
    copyAction(item){
      this.mediaRecordState.stateLoading();
      Api.service.medRecord.medRecordCopy(item.id, ()=>{
        this.mediaRecordState.stateSuccess();
        this.$store.commit('alertSuccess', 'Успешно скопировано');
        this.mediaRecordLoad();
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .action-btns {
    display: flex;
    gap: 10px;
  }
</style>